import { MESSAGE_TYPES } from "src/app/infrastructure/message.types";
import { Location } from '@angular/common';
import { Injectable } from "@angular/core";
import { KeyValuePair } from "src/app/infrastructure/types";
import { MeStateChangedMessageData } from "src/app/infrastructure/message.data.types";
import { ECommerceTrackingItem } from "./model/eCommerceTrackingItem";
import { ISubscriber } from "../messenger.module/model/model";
import { LoggingService } from "../logging.module/logging.service";
import { Messenger } from "../messenger.module/messenger";
import { PlatformHelpersService } from "../platform.helpers.module/platform.helpers.service";

declare const ga: any;
declare var gtm: any;
// declare const fbq: any;

@Injectable({providedIn: 'root'})
export class TrackingService {
    private currentPageName: string;
    private currentDetailsReferrer: string;
    private currentValuationReferrer: string;
    private userId: number = null;
    private isMobileApp: boolean;
    private mobileAppVersion: string;
    private mobileAppOs: string;
    private previousTrackedPageViewUrl: string;
    private shouldTrackPageViewWhenMeStateChanges: boolean = false;
    private featuresString: string = "";

    private tempDataLayer = new Array<any>();

    private GTM_KEY = "GTM-K2PHW3Z";

    private meStateChangedSubscriber: ISubscriber = {
        messageType: MESSAGE_TYPES.ME_STATE_CHANGED,
        On: (data: MeStateChangedMessageData) => {
            if (data) {
                this.userId = null;
                if (data && data.me) {
                    this.userId = data.me.userId;
                    this.TrackLogin();
                }
            }
        }
    }

    private get currentUrl(): string {
        let url = this.location.path();
        if (url.length === 0 || url.startsWith("?")) { url = "/"; }
        return url;
    }

    constructor(
        private location: Location,
        private platformService: PlatformHelpersService,
        private messenger: Messenger,
        private loggingService: LoggingService
    ) {
        this.messenger.Subscribe(this.meStateChangedSubscriber);
        // this.ClearDataLayer();
    }

    SetMobileAppValues(isMobileApp: boolean, mobileAppVersion: string, mobileAppOs: string): void {
        this.isMobileApp = isMobileApp;
        this.mobileAppOs = mobileAppOs;
        this.mobileAppVersion = mobileAppVersion;
    }

    GetMobileAppValues() {
        if (this.isMobileApp) {
            return {
                mobileAppOs: this.mobileAppOs,
                mobileAppVersion: this.mobileAppVersion
            }
        }
        return null;
    }

    SetCurrentPageName(pageName: string): void {
        this.currentPageName = pageName;
        this.TrackPageView();
    }

    GetCurrentPageName(): string {
        return this.currentPageName;
    }

    SetCurrentDetailsReferrer(referrer: string): void {
        this.currentDetailsReferrer = referrer;
    }

    GetCurrentDetailsReferrer(): string {
        return this.currentDetailsReferrer;
    }

    SetCurrentValuationReferrer(referrer: string): void {
        this.currentValuationReferrer = referrer;
    }

    GetCurrentValuationReferrer(): string {
        return this.currentValuationReferrer;
    }

    SetFeatureTests(value: Array<KeyValuePair<string, string>>): void {
        this.featuresString = "";
        for (var i = 0; i < value.length; i++) {
            this.featuresString += value[i].key + ":" + value[i].value + ";";
        }
    }

    TrackECommerceImpressions(eCommerceArray: Array<ECommerceTrackingItem>): void {
        var segmentSize = 6;
        var loops = Math.floor(eCommerceArray.length / segmentSize);

        if (eCommerceArray.length % segmentSize > 0) {
            ++loops;
        }

        for (var l = 0; l < loops; l++) {
            var itemToPush = {
                'event': 'view_item_list',
                'ecommerce': {
                    'items': [],
                    'item_list_id': this.ToLower(eCommerceArray[0].clientListForImpressions),
                    'item_list_name': this.ToLower(eCommerceArray[0].clientListForImpressions)
                }
            };
            for (var i = l * segmentSize; i < l * segmentSize + segmentSize; i++) {
                if (i < eCommerceArray.length) {

                    var categories = eCommerceArray[i].category.split("/");

                    var item = {
                        'item_id': eCommerceArray[i].id,
                        'item_name': eCommerceArray[i].name,
                        'item_brand': eCommerceArray[i].brand,
                        'item_category': categories[0],
                        'item_category2': categories[1],
                        'item_category3': categories[2],
                        'item_category4': categories[3],
                        'item_category5': categories[4],
                        'coupon': eCommerceArray[i].coupon,
                        'item_variant': eCommerceArray[i].variant,
                        'price': eCommerceArray[i].price,
                        'item_list_name': this.ToLower(eCommerceArray[i].clientListForImpressions),
                        'index': eCommerceArray[i].position,
                        'quantity': 1
                    };
                    itemToPush.ecommerce.items.push(item);
                }
            }
            this.PushToDataLayer(itemToPush);
            
            
        }
    }

    TrackProductClick(eCommerceTrackingItem: ECommerceTrackingItem): void {

        var categories = eCommerceTrackingItem.category.split("/");

        var itemToPush = {
            'event': 'select_item',
            'ecommerce': {
                'item_list_id': this.ToLower(eCommerceTrackingItem.clientListForImpressions),
                'item_list_name': this.ToLower(eCommerceTrackingItem.clientListForImpressions),
                'items': [
                    {
                        'item_id': eCommerceTrackingItem.id,
                        'item_name': eCommerceTrackingItem.name,
                        'item_brand': eCommerceTrackingItem.brand,
                        'item_category': categories[0],
                        'item_category2': categories[1],
                        'item_category3': categories[2],
                        'item_category4': categories[3],
                        'item_category5': categories[4],
                        'coupon': eCommerceTrackingItem.coupon,
                        'item_variant': eCommerceTrackingItem.variant,
                        'price': eCommerceTrackingItem.price,
                        'item_list_name': this.ToLower(eCommerceTrackingItem.clientListForImpressions),
                        'index': eCommerceTrackingItem.position,
                        'quantity': 1
                    }
                ]
            }
        };
        this.PushToDataLayer(itemToPush);
    }

    TrackDetailDisplay(eCommerceTrackingItem: ECommerceTrackingItem) {
        

        var list = this.currentDetailsReferrer;
        if (!list) {
            list = this.currentPageName;
        }

        var categories = eCommerceTrackingItem.category.split("/");

        var itemToPush = {
            'event': 'view_item',
            'ecommerce': {
                'items': [
                    {
                    'item_id': eCommerceTrackingItem.id,
                    'item_name': eCommerceTrackingItem.name,
                    'item_brand': eCommerceTrackingItem.brand,
                    'item_category': categories[0],
                    'item_category2': categories[1],
                    'item_category3': categories[2],
                    'item_category4': categories[3],
                    'item_category5': categories[4],
                    'coupon': eCommerceTrackingItem.coupon,
                    'item_variant': eCommerceTrackingItem.variant,
                    'price': eCommerceTrackingItem.price,
                    'item_list_name': list,
                    'index': eCommerceTrackingItem.position,
                    'quantity': 1
                    }
                ]
            
            }
        };
        
        this.PushToDataLayer(itemToPush);
        
        
    }

    TrackConversion(eCommerceTrackingItem: ECommerceTrackingItem, isPhone: boolean, isSale: boolean, referrer?: string, eventName?: string, affiliation?: string): void {
        //this.ClearDataLayer();

        if (!referrer) { referrer = this.currentDetailsReferrer }

        var categories = eCommerceTrackingItem.category.split("/");

        var itemToPush = {
            'event': 'purchase',
            'ecommerce': {
                'transaction_id': eCommerceTrackingItem.id.toString() + "_" + Date.now(),
                'affiliation': affiliation || eCommerceTrackingItem.affiliation,
                'value': eCommerceTrackingItem.price.toString(),
                'currency':'GBP',
                'coupon': eCommerceTrackingItem.coupon,
                'items': [
                    {
                        'item_id': eCommerceTrackingItem.id,
                        'item_name': eCommerceTrackingItem.name,
                        'item_brand': eCommerceTrackingItem.brand,
                        'item_category': categories[0],
                        'item_category2': categories[1],
                        'item_category3': categories[2],
                        'item_category4': categories[3],
                        'item_category5': categories[4],
                        'coupon': eCommerceTrackingItem.coupon,
                        'item_variant': eCommerceTrackingItem.variant,
                        'price': eCommerceTrackingItem.price,
                        'item_list_name': this.ToLower(referrer),
                        'index': eCommerceTrackingItem.position,
                        'quantity': 1
                    }
                ]
            }
        }

        this.PushToDataLayer(itemToPush);
        

    }


    TrackPPCConversion(eCommerceTrackingItem: ECommerceTrackingItem, redirectUrl: string, referrer?: string, affiliation?: string): void {
        
            if (!referrer) {
                referrer = this.currentDetailsReferrer;
            }
    
            var categories = eCommerceTrackingItem.category.split("/");
    
            var itemToPush = {
                'event': 'purchase',
                'ecommerce': {
                    'transaction_id': eCommerceTrackingItem.id.toString() + "_" + Date.now(),
                    'affiliation': affiliation || eCommerceTrackingItem.affiliation,
                    'value': eCommerceTrackingItem.price.toString(),
                    'currency': 'GBP',
                    'coupon': eCommerceTrackingItem.coupon,
                    'items': [{
                        'item_id': eCommerceTrackingItem.id,
                        'item_name': eCommerceTrackingItem.name,
                        'item_brand': eCommerceTrackingItem.brand,
                        'item_category': categories[0],
                        'item_category2': categories[1],
                        'item_category3': categories[2],
                        'item_category4': categories[3],
                        'item_category5': categories[4],
                        'coupon': eCommerceTrackingItem.coupon,
                        'item_variant': eCommerceTrackingItem.variant,
                        'price': eCommerceTrackingItem.price,
                        'item_list_name': this.ToLower(referrer),
                        'index': eCommerceTrackingItem.position,
                        'quantity': 1
                    }]
                },
                
            };
    
            this.PushToDataLayer(itemToPush);
    
            this.PushToDataLayer({
                'event': 'ppc_purchase',
                'conversion_value': eCommerceTrackingItem.price, 
                'currency': 'GBP',
                'campaign_name': affiliation || eCommerceTrackingItem.affiliation,
              });
    }


    TrackPropertyValuation(eCommerceTrackingItems: Array<ECommerceTrackingItem>, listingTypeId: number, totalPrice: number) {
        if (eCommerceTrackingItems.length === 0) { return; }

        var list = this.currentValuationReferrer;
        if (!list) { list = this.currentPageName; }

        var itemToPush = {
            'ecommerce': {
                'purchase': {
                    'actionField': {
                        'list': this.ToLower(this.currentValuationReferrer),
                        'id': eCommerceTrackingItems[0].id,
                        'affiliation': eCommerceTrackingItems[0].affiliation,
                        'coupon': eCommerceTrackingItems[0].coupon,
                        'revenue': totalPrice.toString()
                    },
                    'products': []
                }
            },
            'conversion_price': totalPrice.toString(),
            'conversion_type': "Valuation"
        }

        for (var i = 0; i < eCommerceTrackingItems.length; i++) {
            var eCommerceTrackingItem = eCommerceTrackingItems[i];
            itemToPush.ecommerce.purchase.products.push({
                'id': eCommerceTrackingItem.id,
                'name': eCommerceTrackingItem.name,
                'brand': eCommerceTrackingItem.brand,
                'dimension1': eCommerceTrackingItem.dimension1,
                'category': eCommerceTrackingItem.category,
                'coupon': eCommerceTrackingItem.coupon,
                'variant': eCommerceTrackingItem.variant,
                'price': eCommerceTrackingItem.price.toString(),
                'event': eCommerceTrackingItem.event,
                'list': this.ToLower(this.currentValuationReferrer),
                'position': 1,
                'quantity': 1,
                'metric1': 0,
                'metric2': 0,
                'metric3': listingTypeId === 1 ? 1 : 0,
                'metric4': listingTypeId === 2 ? 1 : 0,
                'metric5': 0,
            });
        }

        this.PushToDataLayer(itemToPush);
        this.PushToDataLayer({
            'event': 'ecPurchaseValuation'
        });
    }

    TrackPageView(): void {
        var url = this.currentUrl;
        if (this.shouldTrackPageViewWhenMeStateChanges || this.previousTrackedPageViewUrl !== url) {
            this.previousTrackedPageViewUrl = url;
            var itemToPush = {
                'event': 'pbPageView',
            };
            this.PushToDataLayer(itemToPush);
        }
    }

    TrackAction(category: string, action: string, label: string): void {
        //this.ClearDataLayer();
        this.PushToDataLayer({
            'event': 'clickTracking',
            'category': category,
            'action': action,
            'label': label
        });
    }

    TrackSignup(): void {
        this.TrackEvent('sign-up');
    }

    TrackLogin(): void {
        this.TrackEvent('login');
    }

    TrackEvent(eventName: string) {
        //this.ClearDataLayer();
        this.PushToDataLayer({
            'event': eventName
        });
    }

    TrackPageViewWhenMeStateChanges(): void {
        this.shouldTrackPageViewWhenMeStateChanges = true;
    }
   

    private PushToDataLayer(itemToPush: any) {
        try {
            if (this.platformService.IsBrowserPlatform) {

                this.platformService.Window.dataLayer.push({ecommerce:null});

                var defaultValues = {
                    'page': this.currentPageName,
                    'url': this.currentUrl,
                    'features': this.featuresString
                }
                if (this.userId) {
                    defaultValues['user_id'] = this.userId.toString();
                }
                if (this.isMobileApp) {
                    defaultValues['mobileAppOs'] = this.mobileAppOs;
                    defaultValues['mobileAppVersion'] = this.mobileAppVersion;
                }
                if (this.platformService.Window.dataLayer) {
                    if (this.tempDataLayer.length === 0) {
                        for (var attrname in defaultValues) { itemToPush[attrname] = defaultValues[attrname]; }
                        this.platformService.Window.dataLayer.push(itemToPush);
                    }
                    else {
                        for (var attrname in defaultValues) { itemToPush[attrname] = defaultValues[attrname]; }
                        this.tempDataLayer.push(itemToPush);
                        this.MoveTempDataLayerToDataLayer();
                    }
                }
                else {
                    for (var attrname in defaultValues) { itemToPush[attrname] = defaultValues[attrname]; }
                    this.tempDataLayer.push(itemToPush);
                }
            }
        }
        catch (error) {
            this.loggingService.LogError({ title: "GTM Error Pushing to Data Layer", error: error });
        }
    }

    SetGtmLoadedHandler(): void {
        if (this.platformService.IsBrowserPlatform && this.platformService.Window) {
            let w = this.platformService.Window;
            if (!!w.google_tag_manager) {
                // Google Tag Manager has already been loaded
            } else {
                let self = this;
                w.addEventListener('gtm_loaded', function () {
                    self.MoveTempDataLayerToDataLayer();
                    self.messenger.Send({
                        messageType: MESSAGE_TYPES.GTM_LOADED,
                        messageData: null
                    });
                });
            }
        }
        else { }
    }

    get gtmLoaded(): boolean {
        if (this.platformService.IsBrowserPlatform && this.platformService.Window) {
            return !!this.platformService.Window.google_tag_manager;
        }
        return false;
    }

    private MoveTempDataLayerToDataLayer() {
        if (this.platformService.Window.dataLayer) {
            while (this.tempDataLayer.length > 0) {
                let itemToPush = this.tempDataLayer.shift();
                this.platformService.Window.dataLayer.push(itemToPush);
            }
        }
    }

    private ToLower(input: string) {
        if (input) {
            return input.toLowerCase();
        }
        return input;
    }
}